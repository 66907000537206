import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ProdutosMlContext } from 'contexts/ProdutosMlContext';
import { DropsContext } from 'contexts/DropsContext';
import { GridContext } from 'contexts/GridContext';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import CopyModal from './Modals/CopyModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Anúncios = () => {
  const rotina = 'ProdutoMl';
  const titulo = 'Anúncios Integrados com Mercado Livre';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const {
    produtosMl,
    getProdutosMl,
    getLoading,
    putProdutoMl,
    putLoading,
    syncProdutoMl,
  } = useContext(ProdutosMlContext);
  const { control, getValues, reset } = useForm({
    defaultValues: produtosMl?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getProdutosMl({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar',
      icon: 'add',
      action: () => navigate('/app/Integrações/MercadoLivre/Manutencao'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: produtosMl?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: produtosMl?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={produtosMl?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Configurações',
      icon: 'settings',
      action: () => navigate('/app/Integrações/MercadoLivre/Configuracoes'),
    },
  ];
  const rowOptions = [
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) =>
        navigate('/app/Integrações/MercadoLivre/Detalhes', { state: row }),
    },
    {
      name: 'Ir para Anúncio',
      icon: 'open_in_new',
      action: ({ row }) => window.open(row?.link),
    },
    {
      name: 'Copiar',
      icon: 'file_copy',
      action: ({ row }) =>
        openModal(<CopyModal row={row} callback={loadGrid} />),
      menu: true,
    },
    {
      name: 'Sincronizar',
      icon: 'sync',
      action: ({ row }) => syncProdutoMl({ data: row, cb: loadGrid }),
      menu: true,
    },
    {
      name: 'Ativar',
      icon: 'key',
      show: ({ row }) => row?.status === 'paused',
      action: ({ row }) =>
        putProdutoMl({
          data: {
            id: row?.id,
            integra_access_id: row?.integra_access_id,
            status: 'active',
          },
          cb: loadGrid,
        }),
      menu: true,
    },
    {
      name: 'Inativar',
      icon: 'key_off',
      show: ({ row }) => row?.status === 'active',
      action: ({ row }) =>
        putProdutoMl({
          data: {
            id: row?.id,
            integra_access_id: row?.integra_access_id,
            status: 'paused',
          },
          cb: loadGrid,
        }),
      menu: true,
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} titulo={titulo} colunas={produtosMl?.colunas} />
      <Card>
        <Grid
          mock={mock}
          grid={produtosMl}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading || putLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={produtosMl} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Anúncios;
